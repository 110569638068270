import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Head from '../components/Head'

const StyledWrapper = styled.section`
  .post-date {
    font-style: italic;
  }

  article {
    text-align: justify;

    p {
      line-height: 2rem;
      font-size: 18px;

      a {
        text-decoration: underline;
      }

      figcaption {
        font-size: 0.8rem;
      }
    }
  }
`

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "D MMM YYYY")
      }
      html
    }
  }
`

const Blog = props => {
  return (
    <Layout>
      <Head title={props.data.markdownRemark.frontmatter.title} />
      <StyledWrapper>
        <header>
          <h2>{props.data.markdownRemark.frontmatter.title}</h2>
          <span className="post-date">
            {props.data.markdownRemark.frontmatter.date}
          </span>
        </header>
        <article
          dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
        ></article>
      </StyledWrapper>
    </Layout>
  )
}

export default Blog
